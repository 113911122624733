<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-card-title class="word-break-normal">
            Отчет по использованию обеспечения (СО)
          </v-card-title>
          <v-card-subtitle>
            Информация об отчете
          </v-card-subtitle>
          <div>
            <v-row class="mx-1">
              <v-col>
                <label>
                  Дата начала
                  <custom-date-picker v-model="from" />
                </label>
              </v-col>
              <v-col>
                <label> Дата окончания
                  <custom-date-picker v-model="to" />
                </label>
              </v-col>
            </v-row>

            <v-col>
              <v-checkbox
                v-model="registered"
                color="teal lighten-2"
                hide-details="true"
                label="Зарегистрировано"
              />
              <v-checkbox
                v-model="permission"
                color="teal lighten-2"
                hide-details="true"
                label="Разрешение"
              />
              <v-checkbox
                v-model="removedFromControl"
                color="teal lighten-2"
                hide-details="true"
                label="Снято с контроля"
              />
              <v-checkbox
                v-model="byDepartments"
                color="teal lighten-2"
                hide-details="true"
                label="По отделам"
              />
            </v-col>

            <v-card-actions class="mt-10 mx-2">
              <v-btn
                class="elevation-0"
                :loading="loading"
                dark
                @click="downloadReport"
              >
                <v-icon>mdi-download</v-icon>
                Скачать
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import CustomDatePicker from '@/components/shared/custom-date-picker.vue';
import {downloadExcel, getFilenameFromContentDisposition} from '@/helpers/documents'
import moment from 'moment'
import axios from "axios";
import {REPORTS} from "@/http-routes";

export default {
  components: {CustomDatePicker},
  data: () => ({
    from: null,
    to: null,
    registered: false,
    permission: false,
    removedFromControl: false,
    byDepartments: false,
    loading: false,
  }),
  methods: {
    getPaymentEnsuringReport({dateFrom, dateTo, registered, permission, removedFromControl, byDepartments}) {
      return axios.get(`${REPORTS}/soStatistics?dateFrom=${dateFrom}&dateTo=${dateTo}&registered=${registered}&permission=${permission}&removedFromControl=${removedFromControl}&byDepartments=${byDepartments}`, {
        responseType: 'blob'
      })
    },
    downloadReport() {
      this.loadingStart()
      const dateFrom = moment(this.from).isValid()
        ? moment(this.from).format("DD.MM.YYYY")
        : null;
      const dateTo = moment(this.to).isValid()
        ? moment(this.to).format("DD.MM.YYYY")
        : null;
      if (!dateFrom || !dateTo) {
        this.loadingEnd()
        return this.$error('Неверная дата')
      }
      this.getPaymentEnsuringReport({
        registered: this.registered,
        permission: this.permission,
        removedFromControl: this.removedFromControl,
        byDepartments: this.byDepartments,
        dateFrom,
        dateTo
      }).then(res => {
        const name = getFilenameFromContentDisposition(res)
        downloadExcel(res.data, name)
      }).catch(() => this.$error('Не удалось сохранить файл'))
        .finally(() => this.loadingEnd())
    },
    loadingStart() {
      this.loading = true
    },
    loadingEnd() {
      this.loading = false
    },
  }

};
</script>
